import { useMemo } from 'react';

import { useIsToss } from '@common/device-manager';
import { convertToSupertossUrl } from '@widgets/web-landing-banner';

import { useGetQuickMenuQuery } from '../query/useGetQuickMenuQuery';

export function useQuickMenuService() {
  const { data, isLoading } = useGetQuickMenuQuery();
  const isToss = useIsToss();

  const refinedMenuList = useMemo(() => {
    return (
      data?.map((menu) => {
        return {
          ...menu,
          landing_url: isToss ? convertToSupertossUrl(menu.landing_url ?? '') : menu.landing_url, // TODO: 딥링크 형식으로 들어오는 엣지케이스 대응하기
        };
      }) ?? []
    );
  }, [data, isToss]);

  return {
    menuList: refinedMenuList,
    isLoading,
  };
}
