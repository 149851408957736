import { VerticalProductCardCarouselContainer } from '@domains/home-common';

import { FirstPurchaseDeals } from '../components/FirstPurchaseDeals';
import { SectionTitle } from '../components/SectionTitle';
import { useFirstPurchaseDeals } from '../service/useFirstPurchaseDeals';

export const FirstPurchaseDealsContainer = () => {
  const { title, subTitle, itemList, button, onImpressionProduct, onClickProduct, onSaveProduct, onClickMore } =
    useFirstPurchaseDeals();

  if (!itemList?.length) return null;

  return (
    <FirstPurchaseDeals button={button} onClickMore={onClickMore} isLazyLoad>
      <VerticalProductCardCarouselContainer
        defaultSize='small'
        title={<SectionTitle title={title} subTitle={subTitle} />}
        items={itemList}
        onImpression={(item, index) => onImpressionProduct(item, index)}
        onClick={(item, index) => onClickProduct(item, index)}
        onLikeClick={(checked, item, index) => onSaveProduct(checked, item, index)}
      />
    </FirstPurchaseDeals>
  );
};
