import { useUBL } from '@common/log-manager';
import { UxGoodsCardItemForVerticalProductCard } from '@widgets/product-card';

import { PageId, PageIdEnum } from '../models/page-info';
import { getObjectId, getObjectType } from './utils';

type HomeNavigationName = 'home_ad' | 'home_brand' | 'home_beauty';

function convertToNavigationFromPageId(page_id: PageId): HomeNavigationName {
  switch (page_id) {
    case PageIdEnum.web_beauty_best:
      return 'home_beauty';
    case PageIdEnum.web_brand:
      return 'home_brand';
    case PageIdEnum.web_home:
      return 'home_ad';
    default:
      if (process.env.NODE_ENV === 'development') {
        throw new Error('일치하는 case가 없습니다.');
      }
      return 'home_ad';
  }
}

/** @public */
export const useHomeUBL = () => {
  const sendUbl = useUBL();

  const pageviewUBL = (page_id: PageId, page_url: string) => {
    sendUbl({
      category: 'pageview',
      navigation: convertToNavigationFromPageId(page_id),
      navigation_sub: { page_url },
      data: {
        is_opened: true,
      },
    });
  };

  const savedProductUBL = (
    category: 'save_product' | 'remove_saved_product',
    page_id: PageId,
    product: UxGoodsCardItemForVerticalProductCard,
    index: number,
  ) => {
    const server_log = getUblServerLog(product);

    sendUbl({
      category,
      navigation: convertToNavigationFromPageId(page_id),
      object_type: getObjectType(product),
      object_id: getObjectId(product),
      object_idx: index,
      data: server_log ? { server_log } : undefined,
    });
  };

  const appDownloadClickUBL = async (page_id: PageId) => {
    sendUbl({
      category: 'click',
      navigation: convertToNavigationFromPageId(page_id),
      object_type: 'banner',
      object_id: 'app_download',
    });
  };

  const productImpressionUBL = (page_id: PageId, product: UxGoodsCardItemForVerticalProductCard, index: number) => {
    const server_log = getUblServerLog(product);

    sendUbl({
      category: 'impression',
      navigation: convertToNavigationFromPageId(page_id),
      object_type: getObjectType(product),
      object_id: getObjectId(product),
      object_idx: index,
      data: server_log ? { server_log } : undefined,
    });
  };

  const productClickUBL = async (page_id: PageId, product: UxGoodsCardItemForVerticalProductCard, index: number) => {
    const server_log = getUblServerLog(product);

    sendUbl({
      category: 'click',
      navigation: convertToNavigationFromPageId(page_id),
      object_type: getObjectType(product),
      object_id: getObjectId(product),
      object_idx: index,
      data: server_log ? { server_log } : undefined,
    });
  };

  return {
    pageviewUBL,
    appDownloadClickUBL,
    productImpressionUBL,
    productClickUBL,
    savedProductUBL,
  };
};

function getUblServerLog(product: UxGoodsCardItemForVerticalProductCard) {
  if (!product.ubl?.server_log || typeof product.ubl.server_log !== 'string') {
    return undefined;
  }

  let server_log: Record<string, unknown> = {};

  try {
    server_log = JSON.parse(product.ubl.server_log);
  } catch (error) {
    console.error(error);
  }

  return server_log;
}
