import { ReactNode, useEffect } from 'react';
import { Gray_Light_100, Gray_Light_900 } from '@croquiscom/zds';
import { css, Global } from '@emotion/react';

import { colorWithAlpha } from '@common/utils';
import { HomeConfigContextValue, HomeConfigProvider, PAGE_ID_WEB_HOME } from '@domains/home-common';
import {
  CardListContainer,
  FirstPurchaseDealsContainer,
  HeaderContainer,
  LandingBannerContainer,
  MainBannerContainer,
  MainContainer,
  QuickMenuContainer,
  SeoContainer,
} from '@domains/home-features';

export interface MainScreenProps extends Pick<HomeConfigContextValue, 'path'> {
  gnb?: ReactNode;
}

export const MainScreen = ({ path }: MainScreenProps) => {
  return (
    <>
      <Global styles={MainGlobalStyle} />
      <SeoContainer />
      <HomeConfigProvider path={path} page_id={PAGE_ID_WEB_HOME}>
        <MainContainer>
          <LandingBannerContainer />
          <HeaderContainer />
          <MainBannerContainer />
          <QuickMenuContainer />
          <FirstPurchaseDealsContainer />
          <CardListContainer page_id={PAGE_ID_WEB_HOME} padding='8px 0px 16px' />
        </MainContainer>
      </HomeConfigProvider>
    </>
  );
};

const MainGlobalStyle = css`
  #__next,
  #root {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    padding-top: var(--safe-area-top, env(safe-area-inset-top));
  }

  a,
  button {
    cursor: pointer;
    transition:
      background 0.25s cubic-bezier(0.33, 1, 0.68, 1),
      color 0.25s cubic-bezier(0.33, 1, 0.68, 1);
    :active {
      background: ${colorWithAlpha(Gray_Light_900, 0.05)};
    }
  }
`;
