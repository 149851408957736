import React, { forwardRef, ReactNode } from 'react';
import { IconLongArrowRightBold, Text } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';

export interface AppUsageConversionBannerProps {
  isVisible: boolean;
  onClick?: () => void;
  bottomOffset?: number;
  heading?: ReactNode;
  description?: ReactNode;
}

export const AppUsageConversionBanner = forwardRef<HTMLDivElement, AppUsageConversionBannerProps>(
  (
    {
      isVisible,
      onClick,
      bottomOffset = 40,
      heading = '더 다양한 상품 추천을 받고 싶다면?',
      description = (
        <>
          <b>지그재그 앱</b>에서 찾아보기
        </>
      ),
    },
    ref,
  ) => {
    return (
      <AnimatePresence>
        {isVisible && (
          <MotionFixed
            key='banner'
            ref={ref}
            variants={bannerVariants(bottomOffset)}
            initial='hidden'
            animate='visible'
            exit='exit'
            bottomOffset={bottomOffset}
          >
            <Wrapper onClick={onClick}>
              <ContentWrap>
                <Text variant='Body_14_SemiBold'>{heading}</Text>
                <Desc variant='Caption_12_SemiBold' color='textSecondary'>
                  {description}
                </Desc>
              </ContentWrap>
              <IconLongArrowRightBold size={24} />
            </Wrapper>
          </MotionFixed>
        )}
      </AnimatePresence>
    );
  },
);

const bannerVariants = (offset: number) => ({
  hidden: {
    opacity: 0,
    y: offset,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.65, 0, 0.35, 1],
    },
  },
  exit: {
    opacity: 0,
    y: offset,
    transition: {
      duration: 0.4,
      ease: [0.65, 0, 0.35, 1],
    },
  },
});

const MotionFixed = styled(motion.div)<{ bottomOffset: number }>`
  position: fixed;
  max-width: 600px;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* 실제 최종 위치 */
  bottom: ${({ bottomOffset }) => `${bottomOffset}px`};
  z-index: 9;
  padding-left: 16px;
  padding-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #292b2b;
  background: ${vars.color.staticWhite};
  backdrop-filter: blur(5px);
  padding: 12px 12px 12px 16px;
`;

const ContentWrap = styled.div`
  margin-right: auto;
`;

const Desc = styled(Text)`
  b {
    color: ${vars.color.accentPinkPrimary};
  }
`;
