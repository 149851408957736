import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { useIsToss } from '@common/device-manager';
import { useTracker } from '@common/log-manager';
import { web_max_width } from '@common/styles';
import { ZigzagTopNavigation, ZigzagTopNavigationItemIcon } from '@common/top-navigation';
import { convertToSupertossUrl } from '@widgets/web-landing-banner';

import { useHomeHeaderUBL } from '../log/useHomeHeaderUBL';
const stickyCss = css`
  z-index: 2;
  position: sticky;
  top: var(--safe-area-top, env(safe-area-inset-top));
  left: 0;
  right: 0;
`;

const wrapperCss = css`
  max-width: ${web_max_width}px;
  margin: auto;
`;

export function HeaderContainer() {
  const { clickHeaderContentsUBL } = useHomeHeaderUBL();
  const isToss = useIsToss();
  const tracker = useTracker();

  const handleLogoClick = () => clickHeaderContentsUBL('zigzag_home');
  const handleCartClick = () => {
    clickHeaderContentsUBL('cart');
    if (isToss) {
      location.href = convertToSupertossUrl(`${location.origin}/cart?from=home`);
      return true;
    }
  };
  const handleSearchClick = () => {
    clickHeaderContentsUBL('search');
    if (isToss) {
      location.href = convertToSupertossUrl(`${location.origin}/search`);
      return true;
    }
  };
  const handleMypageClick = () => {
    tracker.sendImmediately({
      category: 'click',
      navigation: 'home_ad',
      object_section: 'top_bar',
      object_type: 'button',
      object_id: 'mypage',
    });

    if (isToss) {
      location.href = convertToSupertossUrl(`${location.origin}/my-page`);
      return true;
    }
  };

  return (
    <div css={stickyCss}>
      <div css={wrapperCss}>
        <ZigzagTopNavigation.Home onLogoClick={handleLogoClick}>
          {isToss && <ZigzagTopNavigationItemIcon.MypageIcon onClick={handleMypageClick} />}
          <ZigzagTopNavigationItemIcon.SearchIcon onClick={handleSearchClick} />
          <ZigzagTopNavigationItemIcon.CartIcon onClick={handleCartClick} />
        </ZigzagTopNavigation.Home>
      </div>
    </div>
  );
}
