import React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { MiniLoader } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { getSiteIdFromHost, SiteId } from '@common/site-manager';
import { BrandStyleProvider } from '@common/styles';
import { hexToRGBA } from '@common/utils';

import { LoadingDot } from '../LoadingDot/LoadingDot';
import { WaveDots } from '../LoadingDot/WaveDots';

export interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: 'light' | 'dark';
  isMiniLoader?: boolean;
  zIndex?: number;
}

/**
 * @public
 */

export const Loading = (props: LoadingProps) => {
  const { children, className, theme = 'light', isMiniLoader = false, ...otherProps } = props;
  const site_id = getSiteIdFromHost();
  const renderIndicator = () => {
    if (isMiniLoader) {
      return <MiniLoader />;
    }
    switch (site_id) {
      case SiteId.POSTY:
      case SiteId.FBK:
        return <WaveDots />;
      case SiteId.ZIGZAG:
      default:
        return <LoadingDot color={theme === 'light' ? 'black' : 'white'} />;
    }
  };
  return (
    <BrandStyleProvider site_id={site_id}>
      <Backdrop className={className} color={theme === 'light' ? '#FFFFFF' : '#000000'} {...otherProps}>
        <Container>
          {renderIndicator()}
          {children}
        </Container>
      </Backdrop>
    </BrandStyleProvider>
  );
};

const Backdrop = styled.div<{ color: string; zIndex?: number }>`
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ color }) => hexToRGBA(color, 0.7)};
  z-index: ${({ zIndex }) => zIndex ?? 2500};
`;

const Container = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
`;
