import { useUBL } from '@common/log-manager';
import { getServerLogForVerticalProductCard, UxGoodsCardItemForVerticalProductCard } from '@widgets/product-card';

const commonLog = { navigation: 'home_ad', object_section: 'first_purchase' };

/** @public */
export const useFirstPurchaseDealsUBL = () => {
  const sendUBL = useUBL();

  const savedProductUBL = (
    category: 'save_product' | 'remove_saved_product',
    item: UxGoodsCardItemForVerticalProductCard,
    object_idx: number,
  ) => {
    const server_log = getServerLogForVerticalProductCard(item.catalog_product_id, item);

    sendUBL({
      ...commonLog,
      category,
      object_type: 'catalog',
      object_id: item.catalog_product_id || '',
      object_idx,
      data: {
        server_log,
      },
    });
  };

  const productImpressionUBL = (item: UxGoodsCardItemForVerticalProductCard, object_idx: number) => {
    const server_log = getServerLogForVerticalProductCard(item.catalog_product_id, item);

    sendUBL({
      ...commonLog,
      category: 'impression',
      object_type: 'catalog',
      object_id: item.catalog_product_id || '',
      object_idx,
      data: {
        server_log,
      },
    });
  };

  const productClickUBL = (item: UxGoodsCardItemForVerticalProductCard, object_idx: number) => {
    const server_log = getServerLogForVerticalProductCard(item.catalog_product_id, item);

    sendUBL({
      ...commonLog,
      category: 'click',
      object_type: 'catalog',
      object_id: item.catalog_product_id || '',
      object_idx,
      data: {
        server_log,
      },
    });
  };

  const moreClickUBL = () => {
    sendUBL({
      ...commonLog,
      category: 'click',
      object_type: 'button',
      object_id: 'see_all',
    });
  };

  return {
    productImpressionUBL,
    productClickUBL,
    savedProductUBL,
    moreClickUBL,
  };
};
