import { useMemo } from 'react';

import { useIsToss } from '@common/device-manager';
import { formatCategoryList, useInitLikedProducts, useLikeProductService } from '@widgets/like-product';
import { type UxGoodsCardItemForVerticalProductCard } from '@widgets/product-card';
import { convertToSupertossUrl } from '@widgets/web-landing-banner';
import { useFirstPurchaseDealsUBL } from '@domains/home-common';

import { useGetFirstPurchaseDeals } from '../query/useGetFirstPurchaseDeals';

const convertTopAreaList = (list: UxGoodsCardItemForVerticalProductCard[]) =>
  list.map(({ catalog_product_id }) => catalog_product_id || '');

export const useFirstPurchaseDeals = () => {
  const isToss = useIsToss();
  const { data } = useGetFirstPurchaseDeals();
  const { productImpressionUBL, productClickUBL, savedProductUBL, moreClickUBL } = useFirstPurchaseDealsUBL();
  const { onLikeProduct } = useLikeProductService();

  const onImpressionProduct = (item: UxGoodsCardItemForVerticalProductCard, position: number) => {
    productImpressionUBL(item, position);
  };

  const onClickProduct = (item: UxGoodsCardItemForVerticalProductCard, position: number) =>
    productClickUBL(item, position);

  const onSaveProduct = (checked: boolean, item: UxGoodsCardItemForVerticalProductCard, position: number) => {
    const airbridge = {
      brandID: item.shop_id,
      brandName: item.shop_name,
      productID: item.catalog_product_id,
      productName: item.title,
      price: item.final_price,
      categoryID: formatCategoryList(item.managed_category_list, 'id'),
      categoryName: formatCategoryList(item.managed_category_list, 'value'),
    };

    const webLikeProductPayload = {
      checked,
      productData: { catalog_product_id: item.catalog_product_id || '', shop_id: item.shop_id || '' },
      airbridge,
      sendLog: (checked: boolean) => savedProductUBL(checked ? 'save_product' : 'remove_saved_product', item, position),
    };

    onLikeProduct({ webLikeProductPayload });
  };

  const onClickMore = () => moreClickUBL();

  const itemList = useMemo(
    () => data?.data.getWebDdpCarousel?.item.item_list,
    [data?.data.getWebDdpCarousel?.item.item_list],
  );

  const buttonLandingUrl = useMemo(() => {
    const url = data?.data?.getWebDdpCarousel?.button?.landing_url || '';
    return isToss ? convertToSupertossUrl(url) : url;
  }, [data?.data?.getWebDdpCarousel?.button?.landing_url, isToss]);

  useInitLikedProducts({
    data: itemList,
    convert: convertTopAreaList,
  });

  return {
    title: data?.data.getWebDdpCarousel?.header?.title.text || '',
    subTitle: data?.data.getWebDdpCarousel?.header?.subtitle?.text || '',
    itemList,
    button: {
      text: data?.data?.getWebDdpCarousel?.button?.text || '',
      landingUrl: buttonLandingUrl,
    },
    onImpressionProduct,
    onClickProduct,
    onSaveProduct,
    onClickMore,
  };
};
