import { ReactNode } from 'react';
import { css } from '@emotion/react';

import { ProductCardCarousel } from '@widgets/product-card';

export interface VerticalProductCardCarouselContainerProps
  extends ProductCardCarousel.VerticalProductCardSlideItemsProps {
  title?: ReactNode;
}

export const VerticalProductCardCarouselContainer = (props: VerticalProductCardCarouselContainerProps) => {
  const { title, items, onClick, onImpression, onLikeClick, defaultSize, productNameLineClamp } = props;

  const slideItemsProps = {
    items,
    onClick,
    onImpression,
    onLikeClick,
    defaultSize,
    productNameLineClamp,
  } as Omit<typeof props, 'title'>;

  return (
    <section css={wrapperCss}>
      {title && <div css={titleCss}>{title}</div>}
      <ProductCardCarousel.Root>
        <ProductCardCarousel.VerticalProductCardSlideItems {...slideItemsProps} />
      </ProductCardCarousel.Root>
    </section>
  );
};

const wrapperCss = css`
  padding: 0 0 24px;
`;

const titleCss = css`
  padding: 0 16px;
`;
