import { ReactNode } from 'react';
import { NormalButton } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/dist/tokens';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';

interface FirstPurchaseDealsProps {
  children: ReactNode;
  button: { text: string; landingUrl: string };
  onClickMore: () => void;
  isLazyLoad?: boolean;
}

const buttonWrapperCss = css`
  margin: 0 16px;
`;

export const FirstPurchaseDeals = ({ children, button, onClickMore }: FirstPurchaseDealsProps) => {
  return (
    <ListSection>
      {children}
      <div css={buttonWrapperCss}>
        <Link href={button.landingUrl} passHref>
          <NormalButton variant='tertiaryGray' fullWidth UNSTABLE_feedback onClick={onClickMore}>
            {button.text}
          </NormalButton>
        </Link>
      </div>
    </ListSection>
  );
};

const ListSection = styled.div`
  padding: 12px 0 24px 0;
`;

export const Title = styled.h4`
  color: ${vars.v3Color.gray950};
`;

export const SubTitle = styled.p`
  margin-top: 4px;
  color: ${vars.v3Color.gray600};
`;
