import { FC, HTMLAttributes } from 'react';
import { Gray_Light_200, Gray_Light_500 } from '@croquiscom/zds-core';
import styled from '@emotion/styled';

import {
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_300,
  Gray_Light_400,
  Gray_Light_700,
  Typography,
  web_max_width,
} from '@common/styles';

const BrowserHref = {
  business_info: 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2148891525',
  service_terms: 'https://cf.res.s.zigzag.kr/terms/user/service.html',
  privacy: 'https://cf.res.s.zigzag.kr/terms/user/privacy.html',
  escrow_license: 'https://www.nicepay.co.kr/apply/progress/escrow.do#none',
};

export const Footer: FC<HTMLAttributes<HTMLElement>> = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <Title className={Typography.BODY_14_BOLD}>(주)카카오스타일 사업자 정보</Title>
        <Info className={Typography.CAPTION_12_REGULAR}>
          <li>
            <Label>대표이사</Label>
            <Description>서정훈</Description>
          </li>
          <li>
            <Label>주소</Label>
            <Description>경기 성남시 분당구 판교역로 235, H스퀘어 N동 6층</Description>
          </li>
          <li>
            <Label>문의전화</Label>
            <Description>02-1670-8050</Description>
          </li>
          <li>
            <Label>이메일</Label>
            <Description>info@kakaostyle.com</Description>
          </li>
          <li>
            <Label>사업자등록번호</Label>
            <Description>214-88-91525</Description>
          </li>
          <li>
            <Label>통신판매업 신고번호</Label>
            <Description>2024-성남분당A-0172</Description>
          </li>
          <li>
            <Label>호스팅 사업자</Label>
            <Description>(주)카카오스타일</Description>
          </li>
        </Info>
        <List className={Typography.CAPTION_12_MEDIUM}>
          <ListItem>
            <a href={BrowserHref.business_info} target='_blank' rel='noreferrer'>
              사업자 정보 조회
            </a>
          </ListItem>
          <ListItem>
            <a href={BrowserHref.service_terms} target='_blank' rel='noreferrer'>
              이용약관
            </a>
          </ListItem>
          <ListItem>
            <a href={BrowserHref.privacy} target='_blank' rel='noreferrer'>
              개인정보처리방침
            </a>
          </ListItem>
        </List>
        <Paragraph as='div' className={Typography.CAPTION_11_REGULAR}>
          ㈜카카오스타일은 통신판매중개자로서 통신판매의 당사자가 아닙니다.
          <br />
          따라서, 등록된 상품, 거래정보 및 거래에 대하여 ㈜카카오스타일은
          <br />
          어떠한 책임도 지지 않습니다.
          <br />
          <br />
          Copyright © kakaostyle Corp. All rights reserved.
          <br />
          <br />
          <EscrowWrapper className={Typography.CAPTION_10_REGULAR}>
            <EscrowText>
              당사는 고객님의 안전거래를 위해 관련 법률에 의거하여 NICE KCP의 에스크로서비스를 적용하고 있습니다.
            </EscrowText>
            <EscrowLink href={BrowserHref.escrow_license} target='_blank' rel='noreferrer'>
              서비스 가입사실 확인
            </EscrowLink>
          </EscrowWrapper>
        </Paragraph>
      </Wrapper>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background: ${Gray_Light_100};
`;

const Wrapper = styled.div`
  max-width: ${web_max_width}px;
  padding: 30px 16px 72px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 120px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 120px);
  margin: auto;
`;

const Title = styled.h2`
  color: ${Gray_Light_400};
  margin-bottom: 16px;
`;

const Info = styled.ul`
  color: ${Gray_Light_400};
  padding-bottom: 16px;
  margin: 0 0 16px 0;
  border-bottom: 1px solid ${Gray_Light_150};
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  li {
    display: flex;
    margin-bottom: 4px;
  }
`;

const Label = styled.span`
  white-space: pre-wrap;
`;

const Description = styled.div`
  margin-left: 12px;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const ListItem = styled.li`
  color: ${Gray_Light_400};

  &:last-of-type {
    color: ${Gray_Light_700};
  }

  &:not(:last-of-type)::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 2px;
    background: ${Gray_Light_300};
    margin: 0 8px;
    vertical-align: middle;
  }
`;

const Paragraph = styled.p`
  color: ${Gray_Light_300};
  white-space: pre-wrap;
`;

const EscrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EscrowText = styled.span`
  margin-right: 8px;
`;

const EscrowLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border: 1px solid ${Gray_Light_200};
  color: ${Gray_Light_500};
  border-radius: 4px;
  flex-shrink: 0;
`;
