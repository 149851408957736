import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { AppUsageConversionBanner, AppUsageConversionBannerProps } from '../components/AppUsageConversionBanner';
import { useAppUsageConversionService } from '../services/useAppUsageConversionService';

export const AppUsageConversionContainer = (props: AppUsageConversionBannerProps) => {
  const { handleBannerClick, hide, handleImpression } = useAppUsageConversionService();

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && !hide) {
      handleImpression();
    }
  }, [inView, hide]);

  if (hide) return null;

  return <AppUsageConversionBanner ref={ref} {...props} onClick={handleBannerClick} />;
};
