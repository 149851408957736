import { createContext, FC, useContext } from 'react';

import { Log } from './Tracker';

type LogContextValue = Omit<Log, 'category'>;

const UBLContext = createContext<LogContextValue>({ navigation: '' });

export const useUBLContext = () => useContext(UBLContext);

export const UBLProvider: FC<Partial<LogContextValue>> = ({ children, ...logs }) => {
  const ublContext = useContext(UBLContext);
  return <UBLContext.Provider value={{ ...ublContext, ...logs }}>{children}</UBLContext.Provider>;
};

UBLProvider.displayName = 'UBLProvider';
