import { useCallback } from 'react';

import { isMobile, useIsToss } from '@common/device-manager';
import { useUBLContext } from '@common/log-manager';
import { useTracker } from '@common/log-manager';
import { useAirbridgeDeeplinks, WEB_TO_APP_DEEP_LINK } from '@common/marketing-tracker';
import { useBenefitLandingPopup } from '@common/overlay-manager';

import { useAppBannerVisibility } from './useAppInstallBannerVisibility';

export function useAppUsageConversionService() {
  const { navigation } = useUBLContext();
  const isToss = useIsToss();
  const tracker = useTracker();
  const { airbridgeDeeplinks } = useAirbridgeDeeplinks();
  const { open } = useBenefitLandingPopup();
  const { is_app_install_banner_hidden } = useAppBannerVisibility();

  const sendLog = useCallback(() => {
    tracker.sendImmediately({
      category: 'click',
      navigation,
      object_section: 'floating_banner',
      object_type: 'banner',
      object_id: 'app_download',
    });
  }, []);

  const handleBannerClick = () => {
    sendLog();

    if (isMobile()) {
      airbridgeDeeplinks({
        deeplink: WEB_TO_APP_DEEP_LINK.HOME,
        ctaParams: { cta_param_1: isToss ? 'toss_x_zigzag' : 'kr_home_topbanner' },
      });
    } else {
      open();
    }
  };

  const handleImpression = () => {
    tracker.sendImmediately({
      category: 'impression',
      navigation,
      object_section: 'floating_banner',
      object_type: 'banner',
      object_id: 'app_download',
    });
  };

  return { handleBannerClick, hide: is_app_install_banner_hidden, handleImpression };
}
