import { useQuery } from 'react-query';

import { useIsToss } from '@common/device-manager';

import { getQuickMenuInfo } from '../graphql/quick-menu-info.2';
import { GetQuickMenuInfo_page_info_ui_item_list_UxQuickMenu } from '../graphql/quick-menu-info_types.2';

export const useGetQuickMenuQuery = () => {
  const isToss = useIsToss();
  const isNaverBot =
    typeof window !== 'undefined' && (/(Yeti)/i.test(navigator.userAgent) || /(Naverbot)/i.test(navigator.userAgent));
  const external_page_id = isToss ? 'toss_shopping_home' : null;

  return useQuery(
    ['getQuickMenuInfo', 'web_home_quick'],
    () => getQuickMenuInfo({ page_id: 'web_home_quick', external_page_id }),
    {
      select: ({ data }) =>
        data.page_info?.ui_item_list
          .filter(
            (value): value is GetQuickMenuInfo_page_info_ui_item_list_UxQuickMenu => value.__typename === 'UxQuickMenu',
          )
          .flatMap(({ menu_items }) => menu_items),
      enabled: !isNaverBot,
    },
  );
};
