import { useMemo } from 'react';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import has from 'lodash/has';
import omit from 'lodash/omit';

import { formatCategoryList, useInitLikedProducts, useLikeProductService } from '@widgets/like-product';
import { UxGoodsCardItemForVerticalProductCard as UxGoodsCardItem } from '@widgets/product-card';
import { PageId, useHomeUBL } from '@domains/home-common';

import { usePageInfoQuery } from '../quries/usePageInfoQuery';
import { useGetUserNameQuery } from '@widgets/web-landing-banner';

const convertList = (list: UxGoodsCardItem[]) => list.map(({ catalog_product_id }) => catalog_product_id || '');

export function useCardListService({ page_id }: { page_id: PageId }) {
  const { data: user } = useGetUserNameQuery();
  const { data: page_info, hasNextPage, fetchNextPage, isLoading } = usePageInfoQuery({ page_id });
  const { productImpressionUBL, productClickUBL, savedProductUBL } = useHomeUBL();
  const { onLikeProduct } = useLikeProductService();

  const tabTitle = useMemo(() => {
    const name = user?.user_account?.authenticated_name;

    if (!name) return '당신을 위한 추천 아이템';

    return `${name}님을 위한 추천 아이템`;
  }, [user]);

  const product_list = useMemo(
    () =>
      compact(
        flatten(page_info?.pages?.map((value) => value?.ui_item_list)).map(
          (item) => has(item, 'title') && omit(item, ['ranking']),
        ),
      ) as UxGoodsCardItem[],
    [page_info],
  );

  useInitLikedProducts({
    data: product_list,
    convert: convertList,
  });

  const onImpression = (product: UxGoodsCardItem, index: number) => {
    productImpressionUBL(page_id, product, index);
  };

  const onClick = (product: UxGoodsCardItem, index: number) => {
    productClickUBL(page_id, product, index);
  };

  const onLikeClick = (checked: boolean, product: UxGoodsCardItem, index: number) => {
    const airbridge = {
      brandID: product.shop_id,
      brandName: product.shop_name,
      productID: product.catalog_product_id,
      productName: product.title,
      price: product.final_price,
      categoryID: formatCategoryList(product.managed_category_list, 'id'),
      categoryName: formatCategoryList(product.managed_category_list, 'value'),
    };

    const webLikeProductPayload = {
      checked,
      productData: { catalog_product_id: product.catalog_product_id || '', shop_id: product.shop_id || '' },
      airbridge,
      sendLog: (checked: boolean) =>
        savedProductUBL(checked ? 'save_product' : 'remove_saved_product', page_id, product, index),
    };

    onLikeProduct({ webLikeProductPayload });
  };

  return { product_list, tabTitle, hasNextPage, fetchNextPage, isLoading, onImpression, onClick, onLikeClick };
}
