import { useQuery } from 'react-query';

import { getWebDdpCarousel } from '../graphql/ddp-carousel.2';
import { useIsToss } from '@common/device-manager';

export const useGetFirstPurchaseDeals = () => {
  const isToss = useIsToss();
  const isNaverBot =
    typeof window !== 'undefined' && (/(Yeti)/i.test(navigator.userAgent) || /(Naverbot)/i.test(navigator.userAgent));
  const external_page_id = isToss ? 'toss_shopping_home' : '';

  return useQuery('getFirstPurchaseDeals', () => getWebDdpCarousel({ external_page_id }), {
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    enabled: !isNaverBot,
  });
};
