import { useCallback, useRef, useState } from 'react';
import { Text } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { useIntersectionObserver } from '@common/hooks';
import { Loading } from '@common/overlay-manager';
import { VerticalProductCardVirtuosoGridContainer } from '@widgets/product-card';
import { AppUsageConversionContainer } from '@widgets/web-landing-banner';
import { PageId } from '@domains/home-common';

import { Footer } from '../components/Footer';
import { ProductListLoading } from '../components/Loading';
import { useCardListService } from '../services/useCardListService';

// 홈 상품 288개로 제한
const PRODUCT_ITEM_LIMIT = 288;

/** @public */
export function CardListContainer({ page_id, padding }: { page_id: PageId; padding?: string }) {
  const { fetchNextPage, tabTitle, hasNextPage, isLoading, product_list, onImpression, onClick, onLikeClick } =
    useCardListService({ page_id });
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const fetch_enabled = Boolean(hasNextPage && product_list.length < PRODUCT_ITEM_LIMIT);

  const target = useRef<HTMLDivElement>(null);

  const banner = useCallback((_, count: number) => {
    setTimeout(() => {
      setIsBannerVisible(count >= 6);
    });
  }, []);

  useIntersectionObserver(
    {
      target,
      onIntersect: fetchNextPage,
      enabled: fetch_enabled,
      rootMargin: '300px',
    },
    [hasNextPage],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header variant='Body_18_SemiBold'>{tabTitle}</Header>
      <VirtuosoGridContainerWarpper padding={padding}>
        <VerticalProductCardVirtuosoGridContainer
          increaseViewportBy={200}
          items={product_list ?? []}
          onImpression={onImpression}
          onClick={onClick}
          onLikeClick={onLikeClick}
          useWindowScroll
          banner={banner}
        />
      </VirtuosoGridContainerWarpper>
      {fetch_enabled && <Footer />}
      <AppUsageConversionContainer isVisible={isBannerVisible} />
    </>
  );
}

const VirtuosoGridContainerWarpper = styled.section<{ padding?: string }>`
  padding: ${({ padding }) => padding ?? '0 0 24px'};
`;

const Header = styled(Text)`
  padding: 0 16px;
`;
